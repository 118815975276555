<template>
  <div class="nav-wrapper">
    <header>
      <div class="header__area">
        <div
          class="
            header__top
            grey-bg
            pl-55
            pr-55
            header__padding
            d-none d-lg-block
          "
        >
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-xxl-8 col-xl-10 col-lg-10">
                <div class="header__top-left">
                  <div class="header__info">
                    <ul>
                      <li>
                        <a href="javascript:void(0)"
                          ><i class="far fa-map-marker-alt"></i>
                          {{ settings.social.address }}</a
                        >
                      </li>
                      <li>
                        <a :href="`mailto:${settings.social.email}`"
                          ><i class="far fa-envelope-open"></i>
                          {{ settings.social.email }}</a
                        >
                      </li>
                      <li>
                        <a :href="`tel:${settings.social.phone}`"
                          ><i class="fal fa-phone"></i>
                          {{ settings.social.phone }}</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="header-sticky"
          class="header__bottom pl-55 pr-55 header__padding"
        >
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-xxl-3 col-xl-2 col-lg-3 col-md-6 col-sm-6 col-6">
                <div class="logo">
                  <router-link :to="{ name: 'Home' }">
                    <img
                      :src="`${publicPath}img/logo.png`"
                      class="img-fluid"
                      alt="logo"
                    />
                  </router-link>
                </div>
              </div>
              <div class="col-xxl-6 col-xl-6 col-lg-7 d-none d-lg-block">
                <div class="header__bottom-mid d-flex align-items-center">
                  <div class="main-menu">
                    <nav id="mobile-menu">
                      <ul>
                        <li>
                          <router-link :to="{ name: 'Home' }">Home</router-link>
                        </li>
                        <li>
                          <router-link :to="{ name: 'PublicServices' }"
                            >Services</router-link
                          >
                        </li>
                        <li>
                          <router-link :to="{ name: 'PublicProducts' }"
                            >Products</router-link
                          >
                        </li>
                        <li>
                          <router-link :to="{ name: 'PublicAbout' }"
                            >About Us</router-link
                          >
                        </li>
                        <li>
                          <router-link :to="{ name: 'PublicContact' }"
                            >Contact Us</router-link
                          >
                        </li>
                        <li>
                          <router-link :to="{ name: 'PublicGallery' }"
                            >Gallery</router-link
                          >
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              <div class="col-xxl-3 col-xl-4 col-lg-2 col-md-6 col-sm-6 col-6">
                <div
                  class="
                    header__bottom-right
                    d-flex
                    align-items-center
                    justify-content-end
                  "
                >
                  <div class="header__social mr-35 d-none d-xl-block">
                    <ul>
                      <li>
                        <a :href="settings.social.facebook" target="_blank"
                          ><i class="fab fa-facebook-f"></i
                        ></a>
                      </li>
                      <li>
                        <a :href="settings.social.twitter" target="_blank"
                          ><i class="fab fa-twitter"></i
                        ></a>
                      </li>
                      <li>
                        <a :href="settings.social.instagram" target="_blank"
                          ><i class="fab fa-instagram"></i
                        ></a>
                      </li>
                      <li>
                        <a :href="settings.social.linkedin" target="_blank"
                          ><i class="fab fa-linkedin"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                  <div class="header__action">
                    <button
                      type="button"
                      @click="
                        $store.state.drawerOpen = !$store.state.drawerOpen
                      "
                      class="
                        dot-hamburger-btn dot-hamburger-btn-3
                        sidebar-toggle-btn
                        d-lg-none
                        d-xl-none
                      "
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <template v-if="$store.state.drawerOpen">
      <div class="sidebar__area sidebar-opened">
        <div class="sidebar__wrapper">
          <div class="sidebar__close">
            <button
              class="sidebar__close-btn"
              id="sidebar__close-btn"
              @click="$store.state.drawerOpen = !$store.state.drawerOpen"
            >
              <i class="fal fa-times"></i>
            </button>
          </div>
          <div class="sidebar__content">
            <div class="sidebar__logo mb-40">
              <a href="javascript:void(0)">
                <img
                  :src="`${publicPath}img/logo.png`"
                  class="img-fluid w-75"
                  alt="logo"
                />
              </a>
            </div>
            <div class="mobile-menu fix mean-container">
              <div class="mean-bar">
                <a
                  href="javascript:void(0)"
                  class="meanmenu-reveal"
                  style="right: 0px; left: auto; display: inline"
                  ><span
                    ><span><span></span></span></span
                ></a>
                <nav class="mean-nav">
                  <ul style="display: none">
                    <li>
                      <router-link :to="{ name: 'Home' }">Home</router-link>
                    </li>
                    <li>
                      <router-link :to="{ name: 'PublicServices' }"
                        >Services</router-link
                      >
                    </li>
                    <li>
                      <router-link :to="{ name: 'PublicProducts' }"
                        >Products</router-link
                      >
                    </li>
                    <li>
                      <router-link :to="{ name: 'PublicAbout' }"
                        >About Us</router-link
                      >
                    </li>
                    <li>
                      <router-link :to="{ name: 'PublicContact' }"
                        >Contact US</router-link
                      >
                    </li>
                    <li>
                      <router-link :to="{ name: 'PublicGallery' }"
                        >Gallery</router-link
                      >
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div class="sidebar__contact mt-30 mb-20">
              <h4>Contact Info</h4>

              <ul>
                <li class="d-flex align-items-center">
                  <div class="sidebar__contact-icon mr-15">
                    <i class="fal fa-map-marker-alt"></i>
                  </div>
                  <div class="sidebar__contact-text">
                    <a target="_blank" href="javascript:void(0)">{{
                      settings.social.address
                    }}</a>
                  </div>
                </li>
                <li class="d-flex align-items-center">
                  <div class="sidebar__contact-icon mr-15">
                    <i class="far fa-phone"></i>
                  </div>
                  <div class="sidebar__contact-text">
                    <a :href="`tel:${settings.social.phone}`">{{
                      settings.social.phone
                    }}</a>
                  </div>
                </li>
                <li class="d-flex align-items-center">
                  <div class="sidebar__contact-icon mr-15">
                    <i class="fal fa-envelope"></i>
                  </div>
                  <div class="sidebar__contact-text">
                    <a :href="`mailto:${settings.social.email}`">
                      {{ settings.social.email }}
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div class="sidebar__social">
              <ul>
                <li>
                  <a :href="settings.social.facebook" target="_blank"
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                </li>
                <li>
                  <a :href="settings.social.twitter" target="_blank"
                    ><i class="fab fa-twitter"></i
                  ></a>
                </li>
                <li>
                  <a :href="settings.social.instagram" target="_blank"
                    ><i class="fab fa-instagram"></i
                  ></a>
                </li>
                <li>
                  <a :href="settings.social.linkedin" target="_blank"
                    ><i class="fab fa-linkedin"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="body-overlay opened"></div>
    </template>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "NavBar",
  computed: {
    ...mapState({
      settings: (state) => state.settings,
    }),
  },
};
</script>
