<template>
  <footer>
    <div class="footer__area black-bg">
      <div class="footer__top pb-35">
        <div class="footer__sponsors">
          <div class="container">
            <h2 class="sr-only">Sponsors</h2>

            <div class="footer__sponsors__gold">
              <div class="gold">
                <ul class="nav">
                  <li
                    class="footer__sponsors-item external-link nav-item-li"
                    v-for="(partner, i) in partners"
                    :key="'partner' + i"
                  >
                    <a
                      class="parent-item"
                      :href="partner.website || 'javascript:void(0)'"
                      target="_blank"
                      rel="sponsored"
                    >
                      <img
                        alt=""
                        :src="`${apiURL}/${partner.partner_logo}`"
                        class=""
                        style="opacity: 1"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row align-items-center">
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
              <div class="footer__logo">
                <router-link :to="{ name: 'Home' }">
                  <img
                    :src="`${publicPath}img/logo-plus.png`"
                    class="img-fluid"
                    alt="logo"
                  />
                </router-link>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
              <div class="footer__widget mb-50">
                <div class="footer__widget-top">
                  <h3 class="footer__widget-title">About Comapny</h3>
                </div>
                <div class="footer__widget-content">
                  <div class="footer__widget-about">
                    <p>
                      {{ settings.footerInfo }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
              <div class="footer__widget pl-60 footer__pr-40 mb-50">
                <div class="footer__widget-top">
                  <h3 class="footer__widget-title">Quick Links</h3>
                </div>
                <div class="footer__widget-content">
                  <div class="footer__link">
                    <ul>
                      <li>
                        <router-link :to="{ name: 'Home' }">Home</router-link>
                      </li>
                      <li>
                        <router-link :to="{ name: 'PublicServices' }"
                          >Services</router-link
                        >
                      </li>
                      <li>
                        <router-link :to="{ name: 'PublicProducts' }"
                          >Products</router-link
                        >
                      </li>
                      <li>
                        <router-link :to="{ name: 'PublicContact' }"
                          >Contact</router-link
                        >
                      </li>
                      <li>
                        <router-link :to="{ name: 'PublicAbout' }"
                          >About</router-link
                        >
                      </li>
                      <li>
                        <router-link :to="{ name: 'PublicGallery' }"
                          >Gallery</router-link
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
              <div class="footer__widget pl-20 footer__pr-50 mb-50">
                <div class="footer__widget-top">
                  <h3 class="footer__widget-title">Contact Us</h3>
                </div>
                <div class="footer__widget-content">
                  <div class="footer__info">
                    <ul>
                      <li class="d-flex align-items-start">
                        <div class="footer__info-icon mr-20">
                          <i class="fal fa-map-marker-alt"></i>
                        </div>
                        <div class="footer__info-text">
                          <h4>Locations</h4>
                          <a target="_blank" href="javascript:void(0)">{{
                            settings.social.address
                          }}</a>
                        </div>
                      </li>
                      <li class="d-flex align-items-start">
                        <div class="footer__info-icon mr-20">
                          <i class="far fa-envelope-open"></i>
                        </div>
                        <div class="footer__info-text">
                          <h4>Email Us</h4>
                          <a
                            :href="`mailto:${settings.social.email}`"
                            target="_blank"
                            ><span class="__cf_email__">{{
                              settings.social.email
                            }}</span></a
                          >
                        </div>
                      </li>
                      <li class="d-flex align-items-start">
                        <div class="footer__info-icon mr-20">
                          <i class="fal fa-phone"></i>
                        </div>
                        <div class="footer__info-text">
                          <h4>Phone Us</h4>
                          <a
                            :href="`tel:${settings.social.phone}`"
                            target="_blank"
                            >{{ settings.social.phone }}</a
                          >
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer__bottom">
        <div class="container">
          <div class="footer__bottom-border">
            <div class="row align-items-center">
              <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div class="footer__social">
                  <ul>
                    <li>
                      <a :href="settings.social.facebook" target="_blank"
                        ><i class="fab fa-facebook-f"></i
                      ></a>
                    </li>
                    <li>
                      <a :href="settings.social.twitter" target="_blank"
                        ><i class="fab fa-twitter"></i
                      ></a>
                    </li>
                    <li>
                      <a :href="settings.social.instagram" target="_blank"
                        ><i class="fab fa-instagram"></i
                      ></a>
                    </li>
                    <li>
                      <a :href="settings.social.linkedin" target="_blank"
                        ><i class="fab fa-linkedin"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div class="footer__copyright text-sm-end">
                  <p>
                    © {{ new Date().getFullYear() }} Autoplus Coat. All Rights
                    Reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "Footer",
  data: () => ({
    partners: [],
  }),
  computed: {
    ...mapState({
      settings: (state) => state.settings,
    }),
  },
  beforeCreate() {
    this.$store
      .dispatch("getRequest", "ghost/partners/list")
      .then((response) => {
        if (response.data.status) {
          this.partners = response.data.partners;
        }
      });
  },
};
</script>
<style>
.swiper-container-pointer-events {
  touch-action: pan-y;
}
.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

img {
  display: block;
  vertical-align: middle;
}
img {
  max-width: 100%;
  -webkit-transition: opacity 0.5s ease-out;
  transition: opacity 0.5s ease-out;
}
/*! CSS Used from: Embedded */
.footer .nav {
  list-style-type: none;
  margin-bottom: 0;
}
.footer__sponsors {
  padding: 1.25rem 0 2.125rem;
}
@media (min-width: 48rem) {
  .footer__sponsors {
    padding: 1.875rem 0 1.375rem;
  }
}
@media (min-width: 61.25rem) {
  .footer__sponsors {
    padding: 1.375rem 0 1.875rem;
  }
}
@media (min-width: 73.125rem) {
  .footer__sponsors {
    padding: 4rem 0 0;
  }
}
.footer__sponsors .platinum {
  text-align: center;
  margin-bottom: 1rem;
}
.footer__sponsors .platinum .nav {
  margin-left: auto;
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.footer__sponsors .platinum cfc-nav-item {
  width: 100%;
  max-width: 13rem;
}
@media (min-width: 48rem) {
  .footer__sponsors .platinum .nav {
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .footer__sponsors .platinum cfc-nav-item {
    max-width: 14.5rem;
  }
}
.footer__sponsors .gold .nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.footer__sponsors .gold cfc-nav-item {
  -ms-flex-preferred-size: 33.33333%;
  flex-basis: 33.33333%;
  max-width: 10rem;
}
.footer__sponsors .gold .footer__sponsors-item {
  max-width: 8rem;
}
.footer__sponsors .footer__sponsors-item {
  margin: 0 auto;
}
.footer__sponsors .footer__sponsors-item a {
  display: block;
  text-align: center;
}
.footer__sponsors .footer__sponsors-item img {
  display: block;
  margin: auto;
  max-width: 100%;
}
</style>
