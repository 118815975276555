import Vue from "vue";
import App from "./App.vue";
import "@/registerServiceWorker";
import store from "@/store";
import router from "@/router";
import VeeValidate from "vee-validate";
import axios from "axios";
import VueAxios from "vue-axios";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
//Internal stuff created by me
import { globalMethods } from "@/_helpers/global-methods.js";
import HViewModal from "@/components/HViewModal.vue";
import OffCanvas from "@/components/OffCanvas.vue";
import HViewAlert from "@/components/HViewAlert.vue";
import HviewConfirmPopOver from "@/components/HviewConfirmPopOver.vue";
import WolfButton from "@/components/WolfButton.vue";
import HViewUpload from "@/components/HViewUpload";
import LoadScript from "vue-plugin-load-script";
Vue.prototype.$http = axios;
const token = localStorage.getItem("token");
if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] =
    "Bearer " + token;
}

axios.interceptors.request.use(
  (config) => {
    NProgress.start();
    store.state.isLoading = true;
    store.state.ajaxSucceed = false;
    return config;
  },
  (error) => {
    NProgress.done();
    store.state.isLoading = false;
    store.state.activeBtn = null;
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    NProgress.done();
    store.state.isLoading = false;
    store.state.activeBtn = null;
    return response;
  },
  (error) => {
    NProgress.done();
    store.state.isLoading = false;
    store.state.activeBtn = null;
    return Promise.reject(error);
  }
);

const options = {
  latencyThreshold: 200,
  router: true,
  http: true,
};

Vue.use(NProgress, options);
Vue.use(VueAxios, axios);
Vue.use(VeeValidate);
//Vue.use(Notifications);
Vue.use(LoadScript);
Vue.mixin(globalMethods);
Vue.component("hview-modal", HViewModal);
Vue.component("hview-drawer", OffCanvas);
Vue.component("hview-alert", HViewAlert);
Vue.component("hview-pop-over", HviewConfirmPopOver);
Vue.component("wolf-button", WolfButton);
Vue.component("hview-upload", HViewUpload);
Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
