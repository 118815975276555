<template>
  <div class="">
    <div
      id="loading"
      v-if="$store.state.isLoading && $store.state.activeBtn === null"
    >
      <div id="loading-center">
        <div id="loading-center-absolute">
          <div
            class="
              loading-icon
              text-center
              d-sm-flex
              align-items-center
              justify-content-center
            "
          >
            <img
              class="loading-logo mr-10"
              :src="`${publicPath}img/logo-icon.png`"
              alt=""
            />
            <img :src="`${publicPath}img/logo-text.png`" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="progress-wrap">
      <svg
        class="progress-circle svg-content"
        width="100%"
        height="100%"
        viewBox="-1 -1 102 102"
      >
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
      </svg>
    </div>

    <NavBar />

    <main>
      <router-view />
    </main>
    <Footer />
    <hview-modal
      v-if="needWorkingHoursModal"
      @close="needWorkingHoursModal = false"
      style="display: block"
      :modalSize="'opening-hours'"
      :showFooter="false"
      :showHeader="false"
    >
      <div slot="body">
        <button
          data-v-70fbcc27=""
          type="button"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          class="offcanvas-close text-reset"
          @click="needWorkingHoursModal = false"
        ></button>
        <img
          :src="apiURL + '/' + settings.working_hours"
          class="img-fuild"
          alt=""
        />
      </div>
    </hview-modal>
  </div>
</template>
<script>
import NavBar from "./Shared/Navbar.vue";
import Footer from "./Shared/Footer.vue";
export default {
  name: "App",
  data: () => ({
    slimDisplay: null,
    needWorkingHoursModal: false,
    settings: {},
  }),
  components: {
    NavBar,
    Footer,
  },
  created() {
    this.$store
      .dispatch("getRequest", `ghost/frontends/list/general_info`)
      .then((response) => {
        if (response.data.status) {
          this.settings = response.data.row;
          this.$store.commit("SET_SETTINGS", response.data.row);
          if (!sessionStorage.getItem("hasVisted")) {
            this.needWorkingHoursModal = true;
          }
        }
      });
  },
};
</script>
<style lang="css">
#nprogress .bar {
  background: #f64e60 !important;
  height: 4px;
}
#nprogress .spinner {
  display: none !important;
}
.modal-header .close {
  padding: 13px 25px 18px;
  font-size: 2rem;
  color: #fff;
  opacity: 1;
}
.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select,
.form-floating select.form-control:not([size]):not([multiple]) {
  height: calc(2.8rem + 2px);
  padding: 1rem 0.75rem;
  font-size: 0.875rem;
}
.form-floating select.form-control:not([size]):not([multiple]) {
  padding-bottom: 0;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.overlay-opacity-50-hover.customized:after {
  opacity: 0.5 !important;
}

.MuiPaper-root {
  color: #fff;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #111722;
}
.MuiPaper-rounded {
  border-radius: 4px;
}
.MuiPaper-elevation0 {
  box-shadow: none;
}

.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.3928571428571428rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

.MuiTouchRipple-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
  position: absolute;
  border-radius: inherit;
  pointer-events: none;
}

.MuiButtonBase-root {
  color: inherit;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}
.MuiButtonBase-root::-moz-focus-inner {
  border-style: none;
}
@media print {
  .MuiButtonBase-root {
    -webkit-print-color-adjust: exact;
  }
}

.MuiIconButton-root {
  flex: 0 0 auto;
  color: #fff;
  padding: 8px;
  overflow: visible;
  font-size: 1.3928571428571428rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
}
.MuiIconButton-root:hover {
  background-color: rgba(255, 255, 255, 0.08);
}
@media (hover: none) {
  .MuiIconButton-root:hover {
    background-color: transparent;
  }
}
.MuiIconButton-label {
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
}

.MuiTypography-root {
  margin: 0;
}

.MuiLink-underlineHover {
  text-decoration: none;
}

.jss170 {
  overflow: hidden;
  position: relative;
}

.jss172 {
  color: #afafaf;
  cursor: pointer;
  font-size: 15px;
  transition: 200ms;
}
.jss172:hover {
  color: #1f1f1f;
}
.jss172:active {
  color: #1f1f1f;
}
.jss173 {
  color: #494949;
}
.jss174 {
  top: calc(50% - 70px);
  height: 100px;
  position: absolute;
  background-color: transparent;
  z-index: 99999;
}
.jss174:hover .jss176 {
  filter: brightness(120%);
  opacity: 0.4;
  background-color: black;
}
.jss175 {
  top: 0 !important;
  height: calc(100% - 20px - 10px) !important;
}
.jss176 {
  top: 30px;
  color: white;
  cursor: pointer;
  margin: 0 10px;
  position: relative;
  font-size: 30px;
  transition: 200ms;
  background-color: #494949;
}
.jss176:hover {
  opacity: 0.6 !important;
}
.jss177 {
  top: calc(50% - 20px) !important;
}
.jss178 {
  opacity: 0.6;
}
.jss180 {
  right: 0;
}
.jss181 {
  left: 0;
}

.jss169 {
  width: 100%;
}
.page__title-overlay {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.page__title-overlay::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(34, 35, 40, 1);
}
.opening-hours .modal-body {
  padding: 0 !important;
}
@media (min-width: 576px) {
  .modal-dialog.opening-hours {
    max-width: 350px;
    margin: 1.75rem auto;
    margin-top: 0.5rem;
  }
}
@media (max-width: 768px) {
  .modal-dialog.opening-hours {
    max-width: 300px;
    margin: 1.75rem auto;
    margin-top: 0.5rem;
  }
}
.slider__title {
  font-size: 90px;
  margin-bottom: 20px;
}
.offcanvas-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #fff;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
  padding: 0.5rem 0.5rem;
  float: right;
  margin: 0.1rem 0;
}
</style>
